import { mdiArrowLeftCircleOutline } from '@mdi/js';
export default {
    name: 'go-back-wrapper',
    props: {
        to: [String, Object],
    },
    data() {
        return {
            mdiArrowLeftCircleOutline,
        };
    },
    methods: {
        goBack() {
            if (this.to) {
                return this.$router.push(this.to).catch(() => { });
            }
            this.$router.go(-1).catch(() => { });
        },
    },
};
